import type { Result } from '@/types/result';
import { type Component, ref, computed, type ComputedRef, type Ref } from 'vue';
import { storage } from './storage';
import { INFORMATION_SECTION_LENGHT } from '@/view/repository';

export const STEP_STORAGE_KEY = '__step';

interface Callback<T> {
  (value?: T, id?: T): Promise<Result>;
}

interface Stepper<T> {
  index: Ref<number>;
  current: ComputedRef<T>;
  hasNext: ComputedRef<boolean>;
  next: (choice: number) => void;
  hasPrev: ComputedRef<boolean>;
  prev: (choice: number) => void;
}

export interface StepType<T> {
  component: Component<T>;
  props?: T extends Component<infer P> ? Partial<P> : never;
  callback?: Callback<T>;
  value?: T;
}

export const index = ref<number>(storage.get(STEP_STORAGE_KEY) ?? 0);

export function Step<T>(
  component: Component | null,
  props?: T extends Component<infer P> ? Partial<P> : never,
  callback?: Callback<T>,
  value?: T
): StepType<T> {
  return {
    component,
    props,
    callback,
    value,
  };
}

export function useStepper<T>(options: ComputedRef<T[]>): Stepper<T> {
  const current = computed<T>(() => options.value[index.value]);

  const hasNext = computed<boolean>(() => index.value + 1 < options.value.length);

  function next(choice: number) {
    const choices = options.value
      // @ts-ignore
      .filter(({ props }) => props?.question?.id !== undefined)
      // @ts-ignore
      .map(({ props }) => props.question.choices)
      .flat();

    if (!hasNext.value) return;

    const next_id = choices.find(({ id }) => id === choice)?.next_id;

    // @ts-ignore
    const nextIndex = options.value.findIndex(({ props }) => props?.question?.id === next_id);

    if (nextIndex !== 0 && nextIndex !== -1) {
      index.value = nextIndex;

      if (index.value === options.value.length - 1) {
        storage.remove(STEP_STORAGE_KEY);
      }
    } else if (index.value <= INFORMATION_SECTION_LENGHT) {
      index.value++;
    } else {
      index.value = options.value.length - 1;
    }

    storage.set(STEP_STORAGE_KEY, index.value);
  }

  const hasPrev = computed<boolean>(() => index.value > 0);

  function prev(choice: number) {
    const choices = options.value
      // @ts-ignore
      .filter(({ props }) => props?.question?.id !== undefined)
      // @ts-ignore
      .map(({ props }) => props.question.choices)
      .flat();

    if (!hasPrev.value) return;

    const prev_id = choices.find(({ id }) => id === choice)?.next_id;
    // @ts-ignore
    const prevIndex = options.value.findIndex(({ props }) => props?.question?.id === prev_id);

    if (prevIndex !== 0 && prevIndex !== -1) {
      index.value = prevIndex;
    } else {
      index.value--;
    }

    storage.set(STEP_STORAGE_KEY, index.value);
  }

  return { index, current, hasNext, next, hasPrev, prev };
}
